import { useRef } from 'react';
import { GET_GAMES_GAMER_QUERY } from '../../global/gql/queries';
import { GameStatus } from '../../global/consts';
import { useSlider } from '../../hooks/useSlider';
import { GamesData } from '../../global/interfaces';
import { useReneQuery } from '../../hooks';
import Icon from '../Icon/Icon';
import LoadingFallback from '../loading-fallback/loading-fallback';

import './recommendations-gamer.scss';

const RecommendationsGamer = () => {
  const trackRef = useRef<HTMLDivElement>(null);

  const { data: reneGames, loading: gamesLoading } = useReneQuery<{ Games: GamesData }>(GET_GAMES_GAMER_QUERY, {
    variables: {
      status: GameStatus.CONNECT_CONFIRMED,
    },
  });

  const { handleNext, handlePrev } = useSlider({
    trackRef,
    dependency: reneGames,
    gap: 16,
    className: 'current-slide',
  });

  return (
    <div className="recommendations-gamer">
      <button onClick={handlePrev}>
        <Icon name="chevron-left" />
      </button>
      <button onClick={handleNext}>
        <Icon name="chevron-right" />
      </button>
      <div className="recommendations-gamer__games carousel">
        {!reneGames && gamesLoading ? <LoadingFallback /> : null}
        <div className="recommendations-gamer__games" ref={trackRef}>
          {reneGames?.Games.items.map((game) => (
            <div key={game.gameId} className="recommendations-gamer__games_game current-slide">
              <img src={game.image?.url} alt="game" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecommendationsGamer;
