import { useEffect, useRef, useState } from 'react';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useReneQuery } from '../../../../hooks';
import { GET_STRIPE_CONFIG } from '../../../../global/gql/queries';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Dispatcher, Event, OrganizationData, Refetch, UserData } from '../../../../global/interfaces';
import Spinner from '../../../spinner/spinner';
import TopUpStatus from '../top-up-status/top-up-status';

import './stripe.scss';
import Icon from '../../../Icon/Icon';
import { delay } from '../../../../utils';

const Checkout = ({
  amount,
  balance,
  closeModal,
  setMessage,
  refetch,
}: {
  amount: string;
  balance: string;
  closeModal: () => void;
  setMessage: Dispatcher<{ type: 'success' | 'error' | ''; message: string; transactionId?: string }>;
  refetch: Refetch<
    | {
        User: UserData;
      }
    | {
        Organization: OrganizationData;
      }
  >;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loadingPayment, setLoadingPayment] = useState(false);

  const balanceRef = useRef(balance);

  useEffect(() => {
    if (balance) {
      balanceRef.current = balance;
    }
  }, [balance]);

  const handleSubmit = async (e: Event['Button']) => {
    e.preventDefault();

    if (!stripe || !elements) return;
    setLoadingPayment(true);

    const { error, paymentIntent } = await stripe?.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin,
      },
      redirect: 'if_required',
    });

    if (error) {
      setMessage({ type: 'error', message: error.message as string });
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      const oldBalance = balanceRef.current;
      let retryCount = 0;

      // balance is not immediately updated in the organization object upon successful payment
      while (true) {
        await delay(1000);
        await refetch();

        if (balanceRef.current !== oldBalance) {
          break;
        }

        retryCount += 1;
        if (retryCount > 5) {
          break;
        }
      }

      setMessage({ type: 'success', message: 'success', transactionId: paymentIntent.id });
    }

    setLoadingPayment(false);
  };

  return (
    <div className="checkout">
      <PaymentElement />
      <button className="primary-btn" disabled={loadingPayment} onClick={handleSubmit}>
        {loadingPayment ? <Spinner size="sm" /> : `Pay $${amount}`}
      </button>
    </div>
  );
};

const StripeModal = ({
  balance,
  amount,
  clientSecret,
  closeModal,
  refetch,
}: {
  balance: string;
  amount: string;
  clientSecret: string;
  closeModal: () => void;
  refetch: Refetch<
    | {
        User: UserData;
      }
    | {
        Organization: OrganizationData;
      }
  >;
}) => {
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | null>(null);
  const [message, setMessage] = useState<{ type: 'success' | 'error' | ''; message: string; transactionId?: string }>({
    type: '',
    message: '',
    transactionId: '',
  });

  useReneQuery(GET_STRIPE_CONFIG, {
    onCompleted: (data: { StripeConfig: { publishableKey: string } }) => {
      if (data.StripeConfig?.publishableKey) {
        setStripePromise(loadStripe(data.StripeConfig.publishableKey));
      }
    },
  });

  return (
    <div className="stripe-modal">
      {message.type === '' ? (
        <>
          <div className="top-up-credit-modal__heading">
            <h2>Powered by Stripe</h2>
            <button type="button" onClick={closeModal}>
              <Icon name="close" size={24} />
            </button>
          </div>
          {stripePromise && clientSecret && (
            <Elements
              stripe={stripePromise}
              options={{ clientSecret, appearance: { variables: { colorText: 'white' } } }}
            >
              <Checkout
                amount={amount}
                balance={balance}
                refetch={refetch}
                closeModal={closeModal}
                setMessage={setMessage}
              />
            </Elements>
          )}
        </>
      ) : (
        <TopUpStatus
          balance={balance}
          amount={amount}
          transactionId={message.transactionId}
          isSuccess={message.type === 'success'}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default StripeModal;
