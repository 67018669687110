import React from 'react';
import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';
import { LOGIN } from './global/routes';
import { TOKEN } from './global/consts';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ApolloClient, ApolloProvider, createHttpLink, ServerError, from, InMemoryCache } from '@apollo/client';
import { checkTokenExpiration, refreshToken } from './utils';
import { NotificationProvider } from './context/notification-context';
import { UpdatePopUpProvider } from './context/update-message-context';
import { ErrorProvider } from './context/error-context';
import { ErrorLink, onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import App from './App';

const reneApiUri = process.env.REACT_APP_RENE_API_URI;
const reneApiKey = process.env.REACT_APP_RENE_API_KEY as string;

const httpLink = createHttpLink({
  uri: reneApiUri,
});

const authLink = setContext(async (_, { headers }: { headers?: { authorization: string } }) => {
  // get the authentication token from local storage if it exists
  let token = localStorage.getItem(TOKEN);

  if (token && checkTokenExpiration(token)) {
    const newToken = await refreshToken(token);
    if (newToken) {
      token = newToken;
    }
  }

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      'x-api-key': token ? '' : reneApiKey,
      authorization: token ? `Bearer ${token}.${headers?.authorization}` : '',
    },
  };
});

const errorLink = onError((({ networkError, operation }) => {
  if (networkError && (networkError as ServerError).statusCode === 401) {
    window.location.href = `/${LOGIN}`;
    localStorage.clear();
    operation.setContext({
      headers: {
        'x-api-key': reneApiKey,
        authorization: '',
      },
    });
  }
}) as ErrorLink.ErrorHandler);

// Initialize ApolloClient
const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      User: {
        keyFields: ['userId'],
      },
      Organization: {
        keyFields: ['orgId'],
        fields: {
          Balance: {
            keyArgs: ['balanceId'],
          },
        },
      },
    },
  }),
});

// 1. Get projectId
const projectId = '86713826d11d89f8a5ea72b552ea8954';
// 2. Set chains
const blast = {
  chainId: 81457,
  name: 'Blast Mainnet',
  currency: 'ETH',
  explorerUrl: 'https://blastscan.io',
  rpcUrl: 'https://rpc.blast.io',
};

const blastTest = {
  chainId: 168587773,
  name: 'Blast Sepolia',
  currency: 'ETH',
  explorerUrl: 'https://testnet.blastscan.io',
  rpcUrl: 'https://sepolia.blast.io',
};

// 3. Create a metadata object
const metadata = {
  name: 'Reneverse',
  description: 'Gaming without borders',
  url: 'https://reneverse.io',
  icons: ['https://cdn.reneverse.io/logos/'],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: '...', // used for the Coinbase SDK
});

const chain = process.env.REACT_APP_CHAIN_MAINNET === 'true' ? [blast] : [blastTest];

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: chain,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <NotificationProvider>
          <UpdatePopUpProvider>
            <ErrorProvider>
              <App />
            </ErrorProvider>
          </UpdatePopUpProvider>
        </NotificationProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
