import { Route, Routes } from 'react-router-dom';
import { ASSETS } from '../../../global/routes';
import GamerAssetsPage from '../gamer-assets-page/gamer-assets-page';
import GamerDashboardPage from '../gamer-dashboard-page/gamer-dashboard-page';
import './gamer-view-page.scss';

const GamerViewPage = () => {
  return (
    <div className="gamer-view-page">
      <Routes>
        <Route index element={<GamerDashboardPage />} />
        <Route path={`:gameId/${ASSETS}`} element={<GamerAssetsPage />} />
      </Routes>
    </div>
  );
};

export default GamerViewPage;
