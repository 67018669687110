import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { GET_USER_QUERY } from '../../global/gql/queries';
import { UserData } from '../../global/interfaces';
import { UserRole } from '../../global/consts';
import {
  GAMES,
  LOGIN,
  ORG,
  SETTINGS,
  ORG_MANAGEMENT,
  DOCS_LINK,
  CAMPAIGNS,
  COLLECTIONS,
  BRANDED_ASSETS,
  // OWNABLE_ASSETS,
} from '../../global/routes';
import { formatNumber, isRoleAllowed, unsubscribeAllSubscriptions } from '../../utils';
import { useReneQuery } from '../../hooks/useReneQuery';
import Icon from '../Icon/Icon';
import avatar from '../../global/images/avatar.webp';
import reneLogo from '../../global/images/rene-logo-small.png';
import reneLogoBig from '../../global/images/rene-logo-big.png';
import Notifications from '../notifications/notifications';
import AsidePopupMenu from '../aside-popup-menu/aside-popup-menu';
import OrganizationMenu from './organization-menu/organization-menu';

import './header.scss';

const DeveloperMenu = ({ user }: { user: UserData }) => {
  const { pathname } = useLocation();
  return (
    <>
      {user && (
        <Link to={`/${ORG}`} className={pathname === `/${ORG}` ? 'page-selected' : ''}>
          Home
        </Link>
      )}
      {user && isRoleAllowed(user?.role, [UserRole.DEVELOPER]) && (
        <Link to={`/${ORG}/${GAMES}`} className={pathname.includes(`/${ORG}/${GAMES}`) ? 'page-selected' : ''}>
          Games
        </Link>
      )}
      {user && isRoleAllowed(user?.role, [UserRole.DEVELOPER, UserRole.ADVERTISER, UserRole.CREATOR]) && (
        <Link
          to={`/${ORG}/${COLLECTIONS}/${BRANDED_ASSETS}`}
          className={pathname.includes(`/${ORG}/${COLLECTIONS}`) ? 'page-selected' : ''}
        >
          Assets
        </Link>
      )}
      {/* <div>
        <p className={pathname.includes(`/${ORG}/${COLLECTIONS}`) ? 'page-selected' : ''}>
          Asset Collections
          <Icon name="chevron-down" size={20} />
        </p>
        <div>
          <Link
            to={`/${ORG}/${COLLECTIONS}/${BRANDED_ASSETS}`}
            className={pathname.includes(`/${ORG}/${COLLECTIONS}/branded`) ? 'page-selected' : ''}
          >
            Branded Collections
          </Link>
          <Link
            to={`/${ORG}/${COLLECTIONS}/${OWNABLE_ASSETS}`}
            className={pathname.includes(`/${ORG}/${COLLECTIONS}/ownable`) ? 'page-selected' : ''}
          >
            Ownable Collections
          </Link>
        </div>
      </div> */}
      {user && isRoleAllowed(user?.role, [UserRole.CREATOR]) && (
        <Link to={`/${ORG}/${CAMPAIGNS}`} className={pathname.includes(`/${ORG}/${CAMPAIGNS}`) ? 'page-selected' : ''}>
          Campaigns
        </Link>
      )}
    </>
  );
};

const GamerMenu = () => {
  const { pathname } = useLocation();
  return (
    <>
      <Link to={`/${GAMES}`} className={pathname === `/${GAMES}` ? 'page-selected' : ''}>
        Home
      </Link>
      <Link to={`/${GAMES}/inbox`} className={pathname.includes(`/${GAMES}/all`) ? 'page-selected' : ''}>
        Inbox
      </Link>
    </>
  );
};

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { data, client, loading } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);
  const isOrgView = !!location.pathname.match(`/${ORG}`);
  const isSettingPage = !!location.pathname.match(`/${SETTINGS}`);
  const isNotGamer = data && data.User.role !== UserRole.GAMER;

  const logout = () => {
    localStorage.clear();
    client.clearStore();
    unsubscribeAllSubscriptions();
    navigate(`/${LOGIN}`);
  };

  if (data && !data?.User.verified.isEmail) {
    logout();
  }

  return (
    <header className="header">
      <div className="header__hamburger_menu">
        <button onClick={() => setIsMenuOpen(true)}>
          <Icon name="hamburger" />
        </button>
        <AsidePopupMenu isMenuOpen={isMenuOpen} setIsOpen={setIsMenuOpen}>
          <div>{isOrgView ? <DeveloperMenu user={data?.User as UserData} /> : <GamerMenu />}</div>
        </AsidePopupMenu>
      </div>
      <div className="header__rene_logo" onClick={() => navigate(isOrgView ? `/${ORG}` : `/${GAMES}`)}>
        <picture>
          <source srcSet={reneLogoBig} media="(min-width: 780px)" />
          <img src={reneLogo} alt="reneverse" />
        </picture>
      </div>
      {!isOrgView && !isSettingPage ? (
        <div className="header__gamer-earnings">
          <p>My earnings</p>
          <div>
            <p>${formatNumber(15723.33, 2)}</p>
            <Icon name="wallet" />
          </div>
        </div>
      ) : null}
      <div className="header__navigation">
        {isOrgView ? <DeveloperMenu user={data?.User as UserData} /> : <GamerMenu />}
      </div>
      <div className="header__user_actions">
        {isOrgView || (isNotGamer && isSettingPage) ? <OrganizationMenu /> : null}
        <Notifications user={data?.User} />
        <div className="header__user_actions_info">
          <div className="header__user_actions_info_image">
            <img src={data?.User?.image?.url || avatar} alt="profile" />
          </div>
          <div className="header__user_actions_info_profile_dropdown">
            {!loading && (
              <div className="header__user_actions_info_profile_dropdown_user">
                <p>{`${data?.User?.data.firstName} ${data?.User?.data.lastName}`}</p>
                <p>{data?.User.email}</p>
              </div>
            )}
            {isNotGamer ? (
              <Link className="header__user_actions_info_profile_dropdown_view" to={isOrgView ? `${GAMES}` : `${ORG}`}>
                <p>Switch to</p>
                <p>{isOrgView ? 'Gamer View' : 'Organization View'}</p>
              </Link>
            ) : null}
            <Link to={`/${SETTINGS}/profile`}>Manage account</Link>
            <Link to={`/${SETTINGS}/wallet`}>Wallet</Link>
            <Link to={`/${SETTINGS}/credit`}>Credits</Link>
            <Link className="header__user_actions_info_profile_dropdown_section" to={`/${SETTINGS}/points`}>
              Points & rewards
            </Link>
            <Link to={`/${ORG_MANAGEMENT}`}>Organizations</Link>
            <a href={DOCS_LINK} target="_blank" rel="noreferrer">
              Help
            </a>
            <button className="header__user_actions_info_profile_dropdown_logout" type="button" onClick={logout}>
              Logout
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
