import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '../../../global/consts';
import { GET_USER_QUERY } from '../../../global/gql/queries';
import { UserData } from '../../../global/interfaces';
import { useReneQuery } from '../../../hooks';
import Input from '../../../components/input/input';
import Select from '../../../components/select/select';
import LoadingFallback from '../../../components/loading-fallback/loading-fallback';

import './admin-dashboard.scss';

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [credits, setCredits] = useState('');
  const [account, setAccount] = useState('');
  const { data: user } = useReneQuery<{ User: UserData } | undefined>(GET_USER_QUERY);

  if (user && user.User.role !== UserRole.ADMIN) {
    navigate(-1);
    return null;
  }

  if (user)
    return (
      <div className="admin">
        <div className="admin-dashboard">
          <div className="admin-dashboard__credit">
            <h2>Select account to add credits</h2>
            <div>
              <Select
                value={account}
                options={['Hrvoje', 'Matija']}
                placeholder="Select account"
                changeHandler={(index) => setAccount(index)}
              />
              <Input
                type="number"
                value={credits}
                handleInput={(e) => setCredits(e.target.value)}
                placeholder="Add credits"
              />
              <button className="primary-btn" disabled={!credits || !account}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    );

  return <LoadingFallback />;
};

export default AdminDashboard;
