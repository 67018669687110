import { Link, useLocation, useParams } from 'react-router-dom';
import { useReneQuery } from '../../../hooks';
import { GET_GAMES_QUERY } from '../../../global/gql/queries';
import { AD_SURFACES, COLLECTIONS, GAMES, ORG, PLAYERS, SETTINGS } from '../../../global/routes';
import { GamesData } from '../../../global/interfaces';
import GameBanner from '../../../components/banners/game-banner/game-banner';
import AdSurfaceList from '../../../components/asset/ad-surface-list/ad-surface-list';
import OrganizationGamePlayers from './organization-game-players/organization-game-players';
import OrganizationGameSettings from './organization-game-settings/organization-game-settings';
import OrganizationGameCollections from './organization-game-collections/organization-game-collections';

import './organization-game-page.scss';

const OrganizationGamePage = () => {
  const params = useParams();
  const { pathname } = useLocation();

  const { data: selectedGame } = useReneQuery<{ Games: GamesData } | undefined>(GET_GAMES_QUERY, {
    variables: { gameId: params.gameId },
  });

  const renderSelectedMenuItem = (selectedMenuItem: string | undefined) => {
    switch (selectedMenuItem) {
      case 'collections':
        return <OrganizationGameCollections />;
      case 'ad_surfaces':
        return (
          <AdSurfaceList id={params.gameId} isUserAllowedToUpsert={true} setOpenMobileMenu={() => {}} type="gameId" />
        );
      case 'players':
        return <OrganizationGamePlayers />;
      case 'settings':
        return <OrganizationGameSettings />;
      default:
        break;
    }
  };

  return (
    <div className="organization-game-page">
      <div className="organization-game-page__heading">
        <GameBanner
          name={selectedGame?.Games.items[0]?.name}
          description={selectedGame?.Games.items[0]?.description}
          stats={selectedGame?.Games.items[0]?.stats}
          gameImage={selectedGame?.Games.items[0]?.image?.url}
          chain={selectedGame?.Games.items[0]?.chain}
          impressions={selectedGame?.Games.items[0].adCampaignStats?.impressions}
        />
      </div>
      <div className="organization-game-page__navigation">
        <Link
          to={`/${ORG}/${GAMES}/${params.gameId}/${COLLECTIONS}`}
          className={pathname.includes(COLLECTIONS) ? 'active-page' : ''}
        >
          Assets
        </Link>
        <Link
          to={`/${ORG}/${GAMES}/${params.gameId}/${AD_SURFACES}`}
          className={pathname.includes(AD_SURFACES) ? 'active-page' : ''}
        >
          Ad Surfaces
        </Link>
        <Link
          to={`/${ORG}/${GAMES}/${params.gameId}/${PLAYERS}`}
          className={pathname.includes(PLAYERS) ? 'active-page' : ''}
        >
          Players
        </Link>
        <Link
          to={`/${ORG}/${GAMES}/${params.gameId}/${SETTINGS}`}
          className={pathname.includes(SETTINGS) ? 'active-page' : ''}
        >
          Settings
        </Link>
      </div>
      <main className="organization-game-page__main">{renderSelectedMenuItem(params.menuItem)}</main>
    </div>
  );
};

export default OrganizationGamePage;
