import { GET_GAMES_GAMER_QUERY } from '../../../global/gql/queries';
import { GamesData } from '../../../global/interfaces';
import { useReneQuery } from '../../../hooks';
import { formatNumber } from '../../../utils';
import Icon from '../../../components/Icon/Icon';
import LoadingFallback from '../../../components/loading-fallback/loading-fallback';
import RecommendationsGamer from '../../../components/recommendations-gamer/recommendations-gamer';

import './gamer-dashboard-page.scss';

const GamerDashboardPage = () => {
  const { data: reneGames, loading: gamesLoading } = useReneQuery<{ Games: GamesData }>(GET_GAMES_GAMER_QUERY);
  return (
    <div className="gamer-dashboard-page">
      <div className="gamer-dashboard-page__offers">
        <div className="gamer-dashboard-page__offers_envelop">
          <Icon name="mail" size={24} />
          <div className="gamer-dashboard-page__offers_envelop_counter">
            <p>{2}</p>
          </div>
        </div>
        <div className="gamer-dashboard-page__offers_text">
          <p>Nike: 15% off new running gear!</p>
          <p>
            Stay ahead of the game with our cutting-edge running gear. Designed for performance, style, and comfort, our
            new collection will help you reach new personal bests.
          </p>
        </div>
      </div>
      <div className="gamer-dashboard-page__earnings">
        <p>My earnings</p>
        <div>
          <p>${formatNumber(15723.33, 2)}</p>
          <Icon name="wallet" />
        </div>
      </div>
      <RecommendationsGamer />
      <div className="gamer-dashboard-page__games">
        <h2>Games</h2>
        {gamesLoading ? (
          <LoadingFallback />
        ) : (
          reneGames?.Games.items.map((game) => (
            <div className="gamer-dashboard-page__games_game">
              <img src={game.image.url} alt="game" />
              <div>
                <h3>{game.name}</h3>
                <div>
                  <p>Connect to earn</p>
                  <p>Action</p>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default GamerDashboardPage;
